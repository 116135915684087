@import url("https://rsms.me/inter/inter.css");

#root {
	height: 100vh;
	overflow: auto;
}

body {
	margin: 0;
	font-family: "Inter var", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #000205;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	user-select: none;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.uiWrapper {
	position: absolute;
	z-index: 5;
	text-align: center;
	height: 100vh;
	width: 100%;
	color: white;
	top: 0;
}

.siteHeader {
	padding-top: 15px;
	font-size: 4em;
	text-align: center;
}

.sectionHeader {
	padding-top: 15px;
	text-align: center;
	/*  */
	-webkit-font-smoothing: auto;
	pointer-events: none;
	color: white;
	font-size: 5em;
	font-weight: 100;
	line-height: 1em;
	margin: 0;
	margin-bottom: 0.25em;
}

.subHeader {
	font-size: 1.6em;
	font-weight: 500;
}

.siteBody {
	/* width: 100%; */
	height: 100vh;
	padding: 0px 15%;
	color: white;
	overflow-y: scroll;
}

.siteBodyInner {
	padding: 20px 70px;
}

.bioInner {
	color: #ffffff;
	line-height: 1.6em;
	font-size: 16px;
	letter-spacing: 1.5px;
}

.navMenu {
	width: 100%;
	padding-top: 15px;
}

.navMenuItem {
	display: inline-block;
	width: 25%;
}

.annotation {
	cursor: pointer;
	outline: none;
	border: none;
	font-size: 24px;
	font-weight: 300;
	background: rgb(215, 215, 215);
	color: #000000;
	padding: 2px 10px;
	letter-spacing: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 5px;
	width: 312px;
	height: 212px;
}

.contactLaptop {
	font-size: 17px;
	text-align: center;
}

.contactHeader {
	font-size: 24px;
}

.loading {
	padding: 10px;
	transform: translate3d(-50%, -50%, 0);
	color: #8b8b8b;
}

@media only screen and (max-width: 600px) {
	.siteHeader {
		font-size: 3em;
	}
	.sectionHeader {
		font-size: 2em;
		padding-top: 80px;
	}
	.subHeader {
		font-size: 1.2em;
	}
	.siteBody {
		/* overflow-y: scroll; */
		padding: 0;
	}
	.siteBodyInner {
		padding: 50px 5px 0px 5px;
		height: 80vh;
		pointer-events: none;
	}
	.bioInner {
		color: #ffffff;
		line-height: 1.6em;
		font-size: 16px;
		letter-spacing: 1.5px;
		height: 74vh;
		overflow-y: auto;
		pointer-events: all;
	}
	.bioBody {
		padding-bottom: 90px;
	}
} 