.scroll {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 80px 0 0 0;
	user-select: none;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	overflow-y: auto;
	scroll-snap-type: y proximity;
	pointer-events: all;
}
  
.scroll > div {
	scroll-snap-align: start;
}

.caption {
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	margin: 80px;
	color: white;
	font-size: 8em;
	font-weight: 100;
	line-height: 1em;
	font-variant-numeric: tabular-nums;
	-webkit-font-smoothing: auto;
}
  

.dot {
	position: sticky;
	top: 0px;
	display: inline-block;
	/* max-width: 800px; */
	max-width: 70%;
	padding: 0;
	color: #ffffff;
	line-height: 1.6em;
	font-size: 20px;
	letter-spacing: 1.5px;
  }
  
  .dot > h1 {
	-webkit-font-smoothing: auto;
	color: white;
	font-size: 5em;
	font-weight: 100;
	line-height: 1em;
	margin: 0;
	margin-bottom: 0.25em;
	pointer-events: auto;
}

a { 
	text-decoration: underline !important; 
	color: white;
}

a:visited { 
	text-decoration: none !important; 
	color: white;
}

.dot > h2 {
	-webkit-font-smoothing: auto;
	/* pointer-events: none; */
	color: white;
	font-size: 3em;
	font-weight: 100;
	line-height: 1em;
	margin: 0;
	margin-bottom: 0.25em;
	pointer-events: auto;
}

@media only screen and (max-width: 1000px) {
	.caption {
	  font-size: 4em;
	}
}
  
@media only screen and (max-width: 800px) {
	.caption {
	  font-size: 3em;
	}
	.dot > h1 {
	  font-size: 3em;
	}
}

@media only screen and (max-width: 770px) {
	.scroll {
		padding: 135px 0 0 0;
	}
}
  
@media only screen and (max-width: 700px) {
	.caption {
		font-size: 2em;
	}
	.dot > h1 {
		font-size: 3em;
	}
	.dot > h2 {
		font-size: 1.8em;
	}
	.scroll {
		padding: 135px 0 0 0;
	}
}
  
@media only screen and (max-width: 600px) {
	.caption {
		font-size: 1em;
	}
	.dot > h1 {
		font-size: 3em;
	}
	.dot {
		font-size: 15px;
	}

}
  